import "./ElectionUsStyle.css";
import photo1 from "../assets/ele1.jpg";
import photo2 from "../assets/ele2.jpg";
import photo3 from "../assets/ele3.jpg";
import photo4 from "../assets/ele4.jpg";
import photo5 from "../assets/ele5.jpg";
import photo6 from "../assets/ele6.jpg";
import photo7 from "../assets/ele7.jpg";
import photo8 from "../assets/ele8.jpg";
import photo9 from "../assets/ele9.jpg";
import photo10 from "../assets/ele10.jpg";
import photo11 from "../assets/ele11.jpg";
import photo12 from "../assets/ele12.jpg";
import photo13 from "../assets/ele13.jpg";


function ElectionUs() {
    return (
      <div className="Election-Wrapper">
        <div className="Election-Photo">
          <img src={photo1} alt="Election 1" />
          <img src={photo2} alt="Election 2" />
          <img src={photo3} alt="Election 3" />
          <img src={photo4} alt="Election 4" />
          <img src={photo5} alt="Election 5" />
          <img src={photo6} alt="Election 6" />
          <img src={photo7} alt="Election 7" />
          <img src={photo8} alt="Election 8" />
          <img src={photo9} alt="Election 9" />
          <img src={photo10} alt="Election 10" />
          <img src={photo11} alt="Election 11" />
          <img src={photo12} alt="Election 12" />
          <img src={photo13} alt="Election 13" />
        </div>
  
        <div className="Election-Button">
          <a href="https://chat.whatsapp.com/GHrwb8WStaVJvlD92NjJX3" target="_blank" rel="noopener noreferrer" className="register-button">
            APPLY NOW
          </a>
        </div>
      </div>
    );
  }
  
  export default ElectionUs;