import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactFormStyles.css';

function ThankYouPopup({ onClose }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>Thank you for your message!</p>
        <button onClick={onClose}>Ok</button>
      </div>
    </div>
  );
}

function ContactForm() {
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4annrk4', 'template_jndfvy3', form.current, 'ik1CAZVM2ZJ-N8fp9')
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
          setShowPopup(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="from-container">
      <form ref={form} onSubmit={sendEmail}>
        <input placeholder="Name" type="text" name="user_name" required />
        <input placeholder="Email" type="email" name="user_email" required />
        <input placeholder="Subject" type="text" name="subject" required />
        <textarea placeholder="Message" name="message" required></textarea>
        <button type="submit">Submit</button>
      </form>
      {showPopup && <ThankYouPopup onClose={closePopup} />}
    </div>
  );
}

export default ContactForm;